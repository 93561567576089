import React from "react"

import styled from "styled-components"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {
  Row,
  ScheduleIntroListItem,
  Section,
  Subtitle,
  Title,
} from "../../components/styled"
import { svgs } from "../../components/svgs"
import { colors } from "../../constants/colors"
import { days } from "../../constants/days"
import { mq } from "../../constants/media-queries"
import { fitSchedule } from "../../content/schedule"

const _reatc = React

const FitScheduleSection = styled(Section)`
  min-height: 100vh;
`

const ScheduleIntroList = styled.ul`
  color: ${colors.black4};
  margin-bottom: 50px;
`

const ScheduleTable = styled.div`
  font-weight: 600;
`

const TitleRow = styled.div`
  background: linear-gradient(90deg, ${colors.pink} 0%, ${colors.purple} 100%);
  display: flex;
  align-items: center;
  color: ${colors.white};
  min-height: 50px;
  padding: 8px 18px;
  font-size: 18px;
  line-height: 1.4;
`
const GroupRow = styled.div`
  display: grid;
  line-height: 1.4;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  ${mq.sm`
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 1fr;
  `}
  align-items: center;
  font-weight: 500;
  background: ${colors.white};
  padding: ${({ pt }) => (pt ? "12px 18px" : "2px 18px 12px")};
  &:not(:last-child) {
    border-bottom: ${({ brb }) => brb && `1px solid ${colors.greyE}`};
  }
  > :first-child {
    grid-column: 1/3;
    font-weight: 600;
    margin-bottom: 10px;
    ${mq.sm`
      grid-column: 1/2;
      margin-bottom: 0;
  `}
    &:empty {
      height: 0;
      margin-bottom: 0;
    }
  }
`
const GroupCell = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
`
const NewTag = styled.div`
  padding: 2px 6px;
  border-radius: 99px;
  border: 1px solid ${colors.pink};
  color: ${colors.pink};
  font-size: 9px;
  margin: 0 4px;
  line-height: 1;
  ${mq.sm`
  font-size: 11px;
  margin: 0 6px;
  `}
  text-transform: uppercase;
  font-weight: 700;
`

const navItems = [
  {
    title: "Fitness",
    path: "/fitness",
  },
  {
    title: "Infos",
    path: "/infos",
  },
]

const SchedulePage = () => (
  <Layout navItems={navItems}>
    <SEO title="Horaires Fitness" />
    <FitScheduleSection darker hero>
      <Row>
        <Subtitle>fitness</Subtitle>
        <Title>Horaires</Title>
        <ScheduleIntroList>
          <ScheduleIntroListItem>
            {svgs.checkmark_outline} DEBUT DES COURS: Lundi 9 septembre 2024
          </ScheduleIntroListItem>
          <ScheduleIntroListItem>
            {svgs.checkmark_outline} FIN DES COURS: Jeudi 5 juin 2025
          </ScheduleIntroListItem>
          <ScheduleIntroListItem>
            {svgs.checkmark_outline} CONGÉS SCOLAIRES ET JUIN: Programme modifié (détérminé avant chaque congé)
          </ScheduleIntroListItem>
          <ScheduleIntroListItem>
            {svgs.checkmark_outline} JUILLET ET AOÛT: Bougez cet été (1 cours
            organisé par semaine)
          </ScheduleIntroListItem>
        </ScheduleIntroList>
        <ScheduleTable>
          <TitleRow>Centre Sportif Jean Moisse / Mont-Saint-Guibert</TitleRow>
          {fitSchedule.map(day =>
            day.classes.map((group, index) => (
              <GroupRow
                brb={index === day.classes.length - 1}
                pt={index === 0}
                key={group.name + group.hours}
              >
                <GroupCell>
                  {index === 0 && days[day.day]}
                  {group.newItems && group.newItems.includes("day") && (
                    <NewTag>new</NewTag>
                  )}
                </GroupCell>

                <GroupCell>
                  {group.hours}
                  {group.newItems?.includes("hours") && <NewTag>new</NewTag>}
                </GroupCell>
                <GroupCell>
                  {group.name}
                  {group.newItems?.includes("name") && <NewTag>new</NewTag>}
                </GroupCell>
              </GroupRow>
            ))
          )}
        </ScheduleTable>
      </Row>
    </FitScheduleSection>
  </Layout>
)

export default SchedulePage
